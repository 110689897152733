/* General body styling */
html, body {
  width: 100%;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body {
  background-color: #0a0a0a;  /* Dark background */
  color: #2b2828;  /* Ensure text remains readable */
  padding: 0 10px;
}

/* General paragraph styling */
p {
  color: #2b2828;
}

/* General heading (h3) styling */
h3 {
  color: #2b2828;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Link for predictions */
.prediction-link {
  text-decoration: none;
  position: relative;
  color: inherit;
  display: block;
}

.prediction-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.prediction-type-select {
  padding: 4px 8px;
  font-size: 1.5em;
  border: none;
  background-color: #5151510f;
  color: #2b2828;
  cursor: pointer;
  width: auto;
}

.prediction-type-select:focus {
  outline: none;
}



/* Main prediction line styling */
.main-prediction-text {
  margin: 0;
  font-size: 1.2em;
}

/* Main prediction line styling */
.secondary-prediction-text {
  margin: 0;
  font-size: 2em;
  padding: 0 0 40px 0;
}

/* Center the form and inputs with a limited width */
.prediction-form {
  max-width: 600px;
  margin: 10px auto;
  text-align: left;
  padding: 20px;
  background-color: #dadada; 
  border-radius: 4px;  /* Smooth rounded corners */
  color: #2b2828;  
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);  /* Subtle shadow for depth */
}

/* Full width for the prediction input, adjusted to respect padding */
.prediction-input {
  width: calc(100% - 20px);  /* Subtract 20px to account for right padding */
  padding: 10px;
  font-size: 1.5em;
  border: none;
  border-bottom: 2px solid #2b2828;  /* Light border for better visibility */
  margin-bottom: 20px;
  background-color: transparent;
  color: #2b2828;  /* Keep text white */
}

/* Prediction details with blue background */
.prediction-details {
  position: relative;
  max-width: 600px;
  margin: 10px auto;
  text-align: left;
  padding: 20px 20px 80px 20px;
  background-color: #dddddd;  /* Blue background */
  border-radius: 4px;
  color: #2b2828;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);  /* Subtle shadow for depth */
}

.privacy-toggle {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  gap: 4px;
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: #5F6368;
}

.privacy-toggle:hover {
  opacity: 1;
}
.share-text {
  font-size: 14px;
  margin-right: 4px;
  color: #5F6368;
}

.prediction-author {
    font-size: 1.5em;
    margin: 0;
    padding: 0 0 0 0;
    color: #666;
}

.prediction-footer {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Small text styling for top-left text */
.small-text {
  font-size: 1.2em;
  margin: 0;
  color: #666;
}


.prediction-details > .small-text {
    padding: 0 0 30px 0;
}

.prediction-history h4 {
    color: #5F6368;
    font-weight: normal;
    font-size: 0.9em;
    margin-bottom: 16px;
}


.update-chance {
  padding: 40px 0;
}


/* Percentage input group */
.chance-group {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
  margin: 10px 0 30px 0;
}

.chance-input {
  width: 80px;
  padding: 8px;
  font-size: 1.5em;
  border: 2px solid #000;
  text-align: center;
}

.share-button-container {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}

.share-button-container .share-menu {
  top: 100%;
  bottom: auto;
  margin-top: 4px;
  margin-bottom: 0;
}

.share-menu {
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 4px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 1000;
  min-width: 150px;
}

.share-menu-item {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px 16px;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  color: #2b2828;
  font-size: 14px;
}


.share-menu-item:hover {
  background-color: rgba(0,0,0,0.05);
}

.share-menu-item svg {
  flex-shrink: 0;
}

span {
  font-size: 2em;
  font-weight: bold;
}

/*.prediction-history {
  padding: 40px 0;
}*/

/* Box around existing predictions */
.prediction-list {
  max-width: 600px;
  margin: 20px auto;
}

.prediction-listed {
  margin: 10px 0;
}


/* Add these to your App.css file */

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 24px 0;
  padding: 16px 0;
}

.pagination-button {
  padding: 8px 16px;
  background-color: #1e9dff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s;
}

.pagination-button:hover {
  background-color: #037fde;
}

.pagination-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.page-info {
  color: #5F6368;
  font-size: 0.9em;
  font-weight: normal;
}



/* Remove bullets from the history list */
.history-list {
  list-style-type: none;  /* No bullets */
  padding-left: 0;  /* Remove left padding */
}

/* Style individual history items */
.history-list li {
  margin-bottom: 10px;  /* Add space between items */
  font-size: 1em;  /* Regular text size */
}

.history-list li strong {
  font-weight: bold;  /* Bold percentage */
  font-size: 1.2em;  /* Slightly larger font for the percentage */
}



/* Style for the SVG icon */
.prediction-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  fill: #888;  /* Light gray color */
  width: 24px;
  height: 24px;
}


/* Back Button Styling */
/*.back-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}*/

.header-buttons {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 1rem;
}

.icon-button {
  background: none;
  border: none;
  padding: 8px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #5F6368;
}

.icon-button:hover {
  background-color: rgba(95, 99, 104, 0.1);
  border-radius: 4px;
}

/* Remove the old back-button styles if they exist */
.back-button {
  display: none;
}



.back-button svg {
  transition: transform 0.3s ease;
  margin-right: 0;  /* Space between the icon and text */
}

.back-text {
  color: #5F6368;  /* Same color as the icon */
  font-size: 16px;
}

.back-button:hover svg,
.back-button:hover .back-text {
  transform: scale(1.1);  /* Slight hover effect */
}


.prediction {
  position: relative;
  border: 1px solid #ccc;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.prediction:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}


.list-share-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 1;
}

.list-share-button:hover {
  background-color: rgba(95, 99, 104, 0.1);
  border-radius: 4px;
}

.update-prediction-btn {
  padding: 10px 20px;
  font-size: 1.2em;
  cursor: pointer;
  background-color: #1e9dff;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
/*  margin-left: auto;*/
}

.update-prediction-btn:hover {
  background-color: #037fde;
}

/* Button styling */
.create-prediction-btn {
  padding: 10px 20px;
  font-size: 1.2em;
  cursor: pointer;
  background-color: #1e9dff;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
/*  margin-left: auto;*/
}

.create-prediction-btn:hover {
  background-color: #037fde;
}


/* Delete Prediction Button */
.delete-prediction-btn {
  background-color: transparent;
  color: #2b2828;  /* Same as the text color */
  border: none;
  font-size: .8em;
  cursor: pointer;
  position: absolute;
  right: 20px;  /* Adjust as necessary */
/*  bottom: 20px;*/
/*  margin-top: 20px;*/
  }

.delete-prediction-btn:hover {
  text-decoration: underline;  /* A subtle underline on hover */
    color: red;  /* Same as the text color */
}


.share-prediction-btn {
  padding: 10px 20px;
  font-size: 1.2em;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.share-prediction-btn:hover {
  background-color: #45a049;
}

.share-section {
  margin-top: 20px;
  text-align: center;
}


.show-all-btn {
  background: none;
  border: none;
  color: #1e9dff;
  padding: 8px 16px;
  font-size: 0.9em;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s;
  border-radius: 4px;
}

.show-all-btn:hover {
  background-color: rgba(30, 157, 255, 0.1);
}

.update-chance {
  padding: 40px 0;
}

.expand-update-btn {
  background-color: transparent;
  border: 1px solid #1e9dff;
  color: #1e9dff;
  padding: 10px 20px;
  font-size: 1.2em;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.expand-update-btn:hover {
  background-color: rgba(30, 157, 255, 0.1);
}

.prediction-history .text-button {
  margin: 8px 0 16px 0;
  display: block;
}

.update-section {
  animation: slideDown 0.3s ease;
  margin-top: 16px;
  margin-bottom: 24px;
}


.update-buttons {
  display: flex;
  gap: 10px;
  margin-left: 10px;
}

.cancel-update-btn {
  padding: 10px 20px;
  font-size: 1.2em;
  cursor: pointer;
  background-color: transparent;
  color: #666;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.cancel-update-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.profile-button {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #a7a7a7;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  z-index: 1000;
  transition: background-color 0.3s;
}

.profile-button:hover {
  background-color: #c0c0c0;
}

/* Profile Page Styles */
.profile-page {
  max-width: 600px;
  margin: 10px auto;
  padding: 20px;
  background-color: #dadada;
  border-radius: 4px;
  color: #2b2828;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.profile-content {
  padding: 20px 0;
}

.profile-section {
  margin-bottom: 30px;
}

.profile-value {
  font-size: 1.2em;
  margin: 5px 0;
}

.profile-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;
}

.change-password-btn {
  padding: 10px 20px;
  font-size: 1.2em;
  cursor: pointer;
  background-color: #1e9dff;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.change-password-btn:hover {
  background-color: #037fde;
}

.password-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0;
}

.password-form input {
  margin-bottom: 8px;
}

.password-buttons {
  display: flex;
  gap: 8px;
}

.success-message {
  color: #4CAF50;
  font-size: 0.9em;
  margin-top: 8px;
}

.error-message {
  color: #dc3545;
  font-size: 0.9em;
  margin-top: 8px;
}



.delete-account-btn {
  padding: 10px 20px;
  font-size: 1.2em;
  cursor: pointer;
  background-color: transparent;
  color: #2b2828;
  border: none;
  margin-top: 20px;
}

.delete-account-btn:hover {
  color: red;
  text-decoration: underline;
}

/* Login Form Styles */
.login-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  color: #5F6368;
  font-size: 0.9em;
}

.form-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 20px;
}

.register-link {
  color: #5F6368;
  font-size: 0.9em;
}

.text-button {
  background: none;
  border: none;
  color: #1e9dff;
  padding: 0;
  font-size: inherit;
  cursor: pointer;
}

.text-button:hover {
  text-decoration: underline;
}

.text-button:disabled {
  color: #666;
  cursor: not-allowed;
}

.text-button:disabled:hover {
  text-decoration: none;
}


.error-message {
  color: #dc3545;
  font-size: 0.9em;
  margin-top: 8px;
  text-align: center;
}
.legal-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: left;
}

.legal-content h1 {
  font-size: 2em;
  margin-bottom: 1em;
}

.legal-content h2 {
  font-size: 1.5em;
  margin-top: 2em;
  margin-bottom: 1em;
}

.legal-content h3 {
  font-size: 1.2em;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}

.legal-content p {
  margin-bottom: 1em;
  line-height: 1.6;
}

.legal-content ul {
  margin-bottom: 1em;
  padding-left: 2em;
}

.legal-content li {
  margin-bottom: 0.5em;
}

.language-switch {
  margin-bottom: 20px;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;  /* Added */
}

/* Content wrapper (add this new class) */
.content-wrapper {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  flex: 1;
}

/* Footer styles */
.footer {
  margin-top: auto;
  padding: 20px 10px;  /* Added horizontal padding */
  width: 100%;
  box-sizing: border-box;  /* Added */
}

.footer-nav {
  max-width: 600px;  /* Match other content width */
  margin: 0 auto;    /* Center the nav */
  display: flex;
  justify-content: center;
  gap: 20px;
}

.footer-nav a {
  color: #5F6368;
  text-decoration: none;
  font-size: 0.9em;
}

.footer-nav a:hover {
  text-decoration: underline;
}

.consent-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 16px 0;
}

.checkbox-label {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  font-size: 0.9em;
  color: #5F6368;
  cursor: pointer;
}

.checkbox-label input[type="checkbox"] {
  margin-top: 3px;
  cursor: pointer;
}

.checkbox-label span {
  font-size: 0.9em;
  font-weight: normal;
}

.consent-link {
  color: #1e9dff;
  text-decoration: none;
}

.consent-link:hover {
  text-decoration: underline;
}

/* Version info styles */
.version-info {
  margin: 20px 0;
  padding: 10px;
  border-radius: 4px;
  text-align: right;
}

.version-history {
  margin-top: 60px;
  padding-top: 20px;
  border-top: 1px solid #ddd;
}

.version-entry {
  margin-bottom: 30px;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 4px;
}

.version-entry h3 {
  color: #1e9dff;
  margin-bottom: 10px;
}

.version-entry h4 {
  margin: 15px 0 10px 0;
}

.version-entry p {
  margin: 5px 0;
  color: #666;
}

.version-entry ul {
  margin: 10px 0;
  padding-left: 20px;
}

.version-entry li {
  margin: 5px 0;
  color: #333;
}


@media (max-width: 768px) {
  .prediction-details {
    padding: 50px 20px 60px 20px;
  }
}